<template>
    <div class="content">
        <img src="../assets/images/img_01.png">
    </div>
</template>

<script>
  export default {
      name: "Home",
      mounted() {

      },
      methods: {

      }
  }
</script>

<style lang="scss" scoped>
.content{
    width: 100%;
    img{
        display: block;
        width: 100%;
    }
}
</style>
